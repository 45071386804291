import {CMSSection} from "@jnext/commons";
import {observer} from "mobx-react";
import {useEffect, useMemo, useRef, useState} from "react";
import {Outlet} from "react-router-dom";
import {useAppStore} from "../../store/appStore";
import {useCMSStore} from "../../store/cmsStore";
import {getHeaderSectionDom} from "../../utils";
import Footer from "../Footer";
import Navbar from "../Navbar";

const PageLayoutTemplate = () => {
    const headerRef = useRef<HTMLDivElement>(null)
    const { structure } = useCMSStore();
    const { mobileLayout } = useAppStore();
    const [openHeroSection, setOpenHeroSection] = useState<boolean>(false)
    const heroSection: CMSSection | undefined = useMemo(() => structure?.header?.sections?.find(e => e?.type === 'HERO_SECTION'), [structure]);
    const isNavbar = !!heroSection && heroSection?.enabled;

    // Tracking dello scrolling
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => window.scrollY === 0 ? setScrolled(false) : setScrolled(true));
    }, [scrolled]);

    const toggleHeroSection = () => {
        setOpenHeroSection(prevState => !prevState)
    }
    return (
        <div className="template">

            {!!heroSection ?
                <div className={`header-fixed${scrolled ? ' scrolled' : ''}`} ref={headerRef}>
                    <header className={`navbar ${mobileLayout ? 'mobile' : ''}`}>
                        <Navbar openHeroSection={openHeroSection} toggleHeroSection={toggleHeroSection} />
                        {getHeaderSectionDom(heroSection, isNavbar, mobileLayout, openHeroSection)}
                    </header>
                </div> :
                <header className="navbar">
                    <Navbar />
                </header>
            }

            <Outlet />

            <footer className="footer">
                <Footer />
            </footer>
        </div>
    );
};

export default observer(PageLayoutTemplate);
