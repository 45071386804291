import React, {useEffect, useMemo, useState} from "react";
import {CatalogService} from "../../service/CatalogService";
import {GetSectorsResponse} from "@jnext/ts-axios-formatdigitalcollection";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {useWindowSize} from "@react-hook/window-size";
import {breakpointsMax} from "@jnext/commons";
import {useTranslationWord} from "../../utils";

interface CategoriesProps {
    onChoose: (sectorId: string | undefined) => void;
    initiativeLogicId: string;
    isPrivate: boolean;
}

export const Categories: React.FC<CategoriesProps> = ({onChoose, initiativeLogicId, isPrivate}) => {
    const [categories, setCategories] = useState<GetSectorsResponse>();
    const [activeCategory, setActiveCategory] = useState<string | undefined>();
    const [width] = useWindowSize();
    const isMobileLayout = useMemo(() => width < breakpointsMax.sm, [width]);
    const [openFilter, setOpenFilter] = useState<boolean>(isMobileLayout);
    const [openSubFilter, setOpenSubFilter] = useState<Map<string, boolean>>(new Map());

    const translateWord = useTranslationWord();

    useEffect(() => {
        (async () => {
            /** Load categories **/
            const data = await CatalogService.getSectors(initiativeLogicId, isPrivate);
            setCategories(data);
        })();
    }, [initiativeLogicId, isPrivate]);

    useEffect(() => {
        setOpenFilter(isMobileLayout);
    }, [isMobileLayout]);

    useEffect(() => {
        if (categories) {
            const initialSubFilters = new Map<string, boolean>();
            [categories]?.forEach((parent) => {
                if (parent.logicId && parent.childs && parent.childs.length > 0) {
                    initialSubFilters.set(parent.logicId, false);
                }
                parent.childs?.forEach((child) => {
                    if (child.logicId && child.childs && child.childs.length > 0) {
                        initialSubFilters.set(child.logicId, false);
                    }
                });
            });
            setOpenSubFilter(initialSubFilters);
        }
    }, [categories]);

    useEffect(() => {
        onChoose(activeCategory);
    }, [activeCategory]);

    const hasActiveChild = (childs?: GetSectorsResponse[]): boolean => {
        return !!childs && childs.some(child => child.logicId === activeCategory || hasActiveChild(child?.childs));
    };

    function chooseFilter(child: GetSectorsResponse, logicId: string | undefined) {
        if (logicId) {
            setActiveCategory(child.logicId);

            setOpenSubFilter(prev => {
                const updated = new Map(prev);
                if (child?.childs?.length) {
                    const isOpen = !updated.get(logicId);
                    updated.set(logicId, isOpen);
                } else {
                    updated.delete(logicId);
                }
                return updated;
            });
        }
    }

    const renderTree = (childs?: GetSectorsResponse[]) => {
        return !!childs && childs.map((child) => {
            const logicId = child.logicId ?? "";
            const isOpen = openSubFilter.get(logicId) || false;
            return (
                <li key={logicId}
                    className={`
                    ${hasActiveChild(child.childs) ? 'open' : ''} 
                    ${logicId === activeCategory ? 'active' : ''}
                `}>
                    <span onClick={() => chooseFilter(child, logicId)}>{child.title}</span>

                    {child?.childs && child.childs.length > 0 &&
                        <>
                            {!isOpen &&
                                <UpOutlined
                                    style={{marginLeft: '0.5rem'}}
                                    onClick={() => chooseFilter(child, logicId)}/>}
                            {isOpen &&
                                <DownOutlined
                                    style={{marginLeft: '0.5rem'}}
                                    onClick={() => chooseFilter(child, logicId)}/>}
                        </>}

                    {child.childs && isOpen &&
                        <ul className={'sublevel'}>
                            {renderTree(child.childs)}
                        </ul>
                    }
                </li>
            );
        });
    };

    return (
        <>
            <div className={'categories-box'}>
                <div className={'categories-title'}>{translateWord('catalog')}</div>
                <div className={'categoriesOutLined'} onClick={() => setOpenFilter(!openFilter)}>
                    {openFilter ? <DownOutlined/> : <UpOutlined/>}
                </div>
            </div>
            {categories && !openFilter &&
                <ul>
                    <li className={`${activeCategory === undefined ? 'active' : ''}`}>
                        <span onClick={() => {
                            setActiveCategory(undefined);
                            setOpenSubFilter(new Map());
                        }}>{translateWord('categoriesListTitle')}</span>
                    </li>
                    {renderTree([categories])}
                </ul>
            }
        </>
    );
};
