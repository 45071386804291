import { Col } from 'antd';
import { FC } from 'react';

import { JHTML } from '@jnext/commons';
import { ProductContentItem } from '../ProductContent';


export type MainInfoSectionProps = {
    title: ProductContentItem['title'],
    shortDescription: ProductContentItem['shortDescription']

}
export const MainInfoSection: FC<MainInfoSectionProps> = (props) => (
    <Col span={24} className="product-main-info">
        {
            props.title && <JHTML.SectionTitle html={props.title} />
        }
        {
            props.shortDescription && <JHTML.Text html={props.shortDescription}></JHTML.Text>
        }
    </Col>
)