import React, {useEffect, useMemo, useState} from "react";
import {CMSConfiguration, NavigationTypes} from "@jnext/commons";
import {useLoadingStore} from "../../../../store/loadingStore";
import FiltersHeader from "./filtersHeader";
import {PointService} from "../../../../service/PointService";
import {useCMSStore} from "../../../../store/cmsStore";
import {TableDesktopView} from "./table/desktop";
import {TableMobileView} from "./table/mobile";
import {Pagination} from "../../../Pagination";

interface HistoryPointsProps{}

const HistoryPoints: React.FC<HistoryPointsProps> = () => {

    const {setLoading} = useLoadingStore();
    const [historyPoints, setHistoryPoints] = useState<any[]>([]);
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState<boolean>(false);
    const [options, setOptions]= useState<any>();
    const [allLogicIds, setAllLogicIds] = useState<string[]>([]);

    //pagination
    const [totalPage, setTotalPage] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>(0);

    //filters
    const [walletLogicId, setWalletLogicId] = useState<string>();
    const [period, setPeriod] = useState<{transactionDateStart:string, transactionDateEnd: string}>();

    // Page CMS
    const {pages} = useCMSStore();
    const config: CMSConfiguration | undefined = useMemo(() => pages?.profile, [pages?.profile]);

    const sections = useMemo(() => {
        if (!config) {
            return [];
        }
        const section = config?.section?.sections?.find(el => el.type == NavigationTypes?.HISTORY_POINTS);
        setOptions(section?.options)

        console.log("section?.options", section?.options)
        // Return sections
        return section?.sections;
    }, [config]);

    // Specific section
    const historyPointsCMS = useMemo(() => sections?.find((el: any) => el.type == 'MAIN'), [sections]);
    const historyNoPointsCMS = useMemo(() => sections?.find((el: any) => el.type == 'NO_DATA'), [sections]);

    useEffect(() => {
        if(!!historyPointsCMS){
            retrieveHistoryPoints();
        }
    }, [historyPointsCMS])

    useEffect(() => {
        if((page || page !== lastPage) || walletLogicId || period){
            setLastPage(page)
            retrieveHistoryPoints()
        }
    }, [page, walletLogicId, period])



    async function retrieveHistoryPoints(){
        try {
            // Show loader
            setLoading(true);
            const size = options?.size || 20;
            let idsLogic = '';

            if(walletLogicId?.toLowerCase() === 'all')
            {
                if(allLogicIds.length > 0){

                    allLogicIds.map((id, index) => {
                        if(index > 0){
                            idsLogic = idsLogic+','+id;
                        }
                        else
                        {
                            idsLogic = idsLogic + id
                        }
                    })
                }
            }
            else
            {
                setAllLogicIds([])
            }

            const res: any = await PointService.retrieveHistoryPoints(page, size, idsLogic.length > 0 ? idsLogic.toString() : walletLogicId,  period);

            if(res){
                setHistoryPoints(res);
                // Set pagination data
                setTotalPage(res?.[0]?.customData?.pageCount);
            }

        } catch (err) {
            setLoading(false);
        } finally {
            // Hide loader
            setLoading(false);
        }
    }

    return <>
        {
            historyPointsCMS &&
            <div className={"historyPoints"}>

                <div className="title">{historyPointsCMS?.title}</div>

                <div className={`popup-filters ${mobileFiltersOpen ? 'open' : 'false'}`}>
                    <FiltersHeader
                        walletLogicId={walletLogicId}
                        setWalletLogicId={async (walletLogicId) => setWalletLogicId(walletLogicId)}
                        setPageNumber={setPage}
                        setAllLogicIds={setAllLogicIds}
                        period={period}
                        setPeriod={async (p)=>setPeriod(p)}
                        closeFilters={() => setMobileFiltersOpen(false)}
                        cms={historyPointsCMS}
                    />
                </div>

                {
                    !!historyPoints && <>
                        <div className={'desktopView'}>
                            <TableDesktopView historyPoints={historyPoints} historyPointsCMS={historyPointsCMS}/>
                        </div>
                        <div className={'mobileView'}>
                            <TableMobileView historyPoints={historyPoints} historyPointsCMS={historyPointsCMS}/>
                        </div>


                        {options?.pagination && totalPage > 1 &&
                            <div className='pagination'>
                                <Pagination
                                    page={page + 1}
                                    totalPage={totalPage}
                                    updatePagination={(pageNr) => setPage(pageNr-1)}
                                />
                            </div>}


                        {
                            historyPoints.length == 0 && <>
                                {historyNoPointsCMS?.title}
                            </>
                        }
                    </>
                }
            </div>
        }
    </>
}

export default HistoryPoints;