import React, {useEffect, useMemo, useState} from "react";
import { Col, Row } from "antd";
import { CatalogPagination } from "./CatalogPagination";
import { CatalogService, GetProductsFilters } from "../../service/CatalogService";
import {CMSSection, scrollTo} from "@jnext/commons";
import { CatalogType } from "../../type/catalogTypes";
import { GetProductsPreviewPagedResponse, MediaTypeEnum } from "@jnext/ts-axios-formatdigitalcollection";
import { CatalogPaginationType } from "../../pages/Catalog/CatalogPage";
import { LoyaltyCollectionCardItem } from "./LoyaltyCollectionCardItem";
import { DigitalWalletCardItem } from "./DigitalWalletCardItem";
import {Options} from "../CatalogSlider/LoyaltyCollectionSlider";

var _ = require('lodash');

interface Pagination {
    hasPagination?: boolean,
    type?: CatalogPaginationType,
    size?: number,
}

export interface CatalogProps {
    colNumbers: number;
    pagination?: Pagination,
    filters?: GetProductsFilters,
    onProductClick: (productId: string) => void,
    containerElement: HTMLElement,
    type: CatalogType;
    initiativeLogicId: string;
    isPrivate: boolean;
    section: CMSSection;
}

export const CatalogList: React.FC<CatalogProps> = ({
                                                        colNumbers,
                                                        pagination,
                                                        filters,
                                                        onProductClick,
                                                        containerElement,
                                                        type,
                                                        initiativeLogicId,
                                                        isPrivate,
                                                        section
                                                    }) => {
    const rewardSpan = 24 / colNumbers;
    const paginationType: CatalogPaginationType = pagination?.type ? pagination?.type : 'LAZY';
    const [catalog, setCatalog] = useState<GetProductsPreviewPagedResponse>({ results: [] });
    const [page, setPage] = useState<number>(0);
    const [lastPage, setLastPage] = useState<number>();
    const [totalPage, setTotalPage] = useState<number>();
    const [lastFilters, setLastFilters] = useState<GetProductsFilters>();
    const [loadTime, setLoadTime] = useState<number>(0);
    const sectionOptions = section?.options as unknown as Options;
    const obtainableOptions = useMemo(()=> (sectionOptions as any)?.obtainable ?? undefined,[sectionOptions])

    useEffect(() => {

        const filterEquals = _.isEqual(lastFilters, filters);

        /**
         * If is the same filters object and the same page -> return
         */
        if (filterEquals && lastPage === page) {
            return;
        }

        /**
         * If filters changed, reset page
         */
        let newPage = page;
        if (!filterEquals) {
            newPage = 0;
        }

        /**
         * Save last filters state and last page
         */
        setLastFilters(filters);
        setPage(newPage);
        setLastPage(newPage);

        (async () => {
            const data = await CatalogService.getProducts(initiativeLogicId, filters || {}, {
                page: newPage,
                pageSize: pagination?.size || 9
            }, isPrivate);

            // If lazy load, prepend old data
            if (data && data?.results && catalog && catalog?.results && paginationType == 'LAZY') {
                data.results?.splice.apply(data, [0, 0, ...catalog.results]);
            }

            setCatalog(data as GetProductsPreviewPagedResponse);

            // Set pagination data
            setTotalPage(data?.pagination?.pageCount);

            // Re-center element in screen
            if (containerElement) {
                if (loadTime != 0) {
                    scrollTo(containerElement);
                }

                setLoadTime(loadTime + 1);
            }


        })();
    }, [filters, page]);


    const CardComponent = type == CatalogType.digitalWallet ? DigitalWalletCardItem : LoyaltyCollectionCardItem;
    const showPriceDigitalCollection:boolean = (type == CatalogType.digitalWallet && (section?.options?.showItemPrice ?? false) == true);
    const showPriceLoyaltyCollection: boolean = (typeof section?.options?.showItemPrice !== 'undefined')  ? ( (section?.options?.showItemPrice) as boolean) : true;
    const showPrice: boolean = type == CatalogType.digitalWallet ? showPriceDigitalCollection : showPriceLoyaltyCollection;

    return (
        <div className={'catalog-list'}>
            <Row gutter={[32, 32]} align={'stretch'}>
                {
                    catalog?.results?.map((el, idx: number) => {
                        // Removing the medias with mediaType CUSTOM from the catalog results
                        const ProductsWithFilteredMedia = ({ ...el, mediaContents: el.mediaContents?.filter(media => media.mediaType !== MediaTypeEnum.Custom) })
                        return (
                            <Col xs={24} sm={12} md={rewardSpan} key={ProductsWithFilteredMedia.logicId || idx} className={'catalog-col'}>
                                <CardComponent
                                    data={ProductsWithFilteredMedia}
                                    obtainableOptions={obtainableOptions}
                                    pricePosition={'TOPRIGHT'}
                                    showTag={true}
                                    showPrice={showPrice}
                                    onClick={() => onProductClick(ProductsWithFilteredMedia.logicId as string)}
                                />
                            </Col>)
                    })
                }
                {
                    pagination?.hasPagination && (!!totalPage && totalPage > 1) &&
                    <Col className='catalogPagination' span={24}>
                        <CatalogPagination
                            page={page + 1}
                            setPage={(page: number) => setPage(page - 1)}
                            totalPage={totalPage}
                            paginationType={paginationType} />
                    </Col>
                }
            </Row>
        </div>
    )
}