import {API, getNavPathFromType, NavigationTypes} from "@jnext/commons";
import {DomainApi, MessageApi} from "@jnext/ts-axios-coreconfiguration";
import axios from "axios";
import {SetLoadingInterface} from "../type/MicroAppInterfaces";
import {browserRedirect} from "../utils";
import {AuthService} from "./AuthService";
import {DomainDto} from "@jnext/ts-axios-coreconfiguration/models/domain-dto";
import {MessageFlatResponseArray} from "@jnext/ts-axios-coreconfiguration/models/message-flat-response-array";
import {appStore} from "../store/appStore";
import {CatalogService} from "./CatalogService";
import {CatalogType} from "../type/catalogTypes";
import {ProjectFrontendComponents} from "@jnext/ts-axios-coreconfiguration/models/project-frontend-components";
import {ArrayOfInitiativeModuleDto} from "@jnext/ts-axios-coreconfiguration/models/array-of-initiative-module-dto";
import {DEFAULT_LANGUAGE} from "../type/languages";

type HeaderProps = {
    tenant: string,
    project: string,
    language: string,
    solution: string,
    user?: string,
    "jak-tenant": string,
    "jak-project": string,
    "jak-language": string,
    "jak-solution": string,
    "jak-user"?: string,
    Authorization?: string,
    "jak-cms-cluster"?: string,
    "jak-client-timeZone"?: string,
    "jak-default-timeZone"?: string,
}

export type CustomProjectDTO = {
    customFrontend?: boolean,
    customFrontendUrl?: string,
    frontendComponents?: ProjectFrontendComponents[]
}

export type LocalDomain = {
    language: string,
    loaded: boolean
}

export class HttpService {

    static env: any;
    static baseUrl: any;
    static headers: any;
    static customProject: CustomProjectDTO;
    static initiativeFormat: ArrayOfInitiativeModuleDto;
    static landingPageScope?: string;
    static setLoading: SetLoadingInterface = (loading: boolean, error?: any) => {
    }

    static setBaseUrl(baseUrl: any) {
        this.baseUrl = baseUrl
    }

    static setEnv(env: any) {
        this.env = env
    }

    static setHeaders(headers: any) {
        this.headers = headers
    }

    static setCustomProject(data: CustomProjectDTO) {
        this.customProject = {
            customFrontend: data?.customFrontend,
            customFrontendUrl: data?.customFrontendUrl,
            frontendComponents: data?.frontendComponents
        }
    }

    static setInitiativeFormat(initiativeFormat: any) {
        this.initiativeFormat = initiativeFormat
    }

    static isReady() {
        return this.env !== undefined && this.baseUrl !== undefined;
    }

    static setLandingPageScope(landingPageScope: any) {
        this.landingPageScope = landingPageScope
    }

    static servicePath(module: string) {
        return this.env?.baseUrl + '/' + module + '/' + AuthService?.loginInfo?.solution + '/' + AuthService?.loginInfo?.project;
    }

    private static serviceBasePath(module: string, isPublic = false) {
        return this.servicePath(module) + '/' + (isPublic ? 'public' : 'private');
    }

    static async publicHttpApi(method: any, module: string, url: string, body?: any, headers: any = AuthService.loginInfo, contentType?: string) {
        return this.httpApi(method, () => this.serviceBasePath(module, true), url, body, headers, contentType);
    }

    public static translateServiceBasePath(ln: string, fileName?: string) {
        return `${this.env?.baseUrl}/core-cms/${AuthService?.loginInfo.solution}/${AuthService?.loginInfo.project}/${AuthService?.authorized ? 'private' : 'public'}/translations/${ln}/${fileName}`;
    }

    static async translateHttpApi(ln: string, fileName?: string) {
        return this.httpApi('GET', () => this.translateServiceBasePath(ln, fileName), '');
    }

    private static async httpApi(method: any, calcBaseUrl: any, url: string, body?: any, headers: any = AuthService.loginInfo, contentType?: string) {

        //ricordarsi di eliminarli e di passare il token;
        let baseUrl = calcBaseUrl();

        this.setLoading(true);
        return API(method, url, body, headers, contentType, baseUrl)
            .then((data: any) => {
                this.setLoading(false);
                //if(method !== "GET") store?.authStore?.setModalMessage({text: 'success'}, 'success')
                return data;
            }).catch(((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    this.setLoading(false);
                    const message = error?.response?.data?.message?.errorCode ? error?.response?.data?.message?.errorCode : error?.response?.data?.message;
                    //store?.authStore?.setModalMessage({text: message}, 'error');
                    throw error;
                }
            }));
    }

    static resolveTimezone(timeZoneClient: string): string {

        return timeZoneClient?.toString()?.includes("+") || timeZoneClient?.toString()?.includes(":")
            ? "Europe/Rome"
            : timeZoneClient;
    }

    /**
     * Get default headers for requests
     * @param overrides - if overrides needed
     */
    static getHttpHeaders({
                              tenant,
                              project,
                              language,
                              solution,
                              token,
                              clusterId
                          }: {
        tenant?: string,
        project?: string,
        language?: string,
        solution?: string,
        token?: string,
        clusterId?: string,
    } = {}) {

        // main object
        const headers: HeaderProps = {

            //delete tenant, project, solution, language
            tenant: tenant || AuthService.loginInfo?.tenant,
            project: project || AuthService.loginInfo?.project,
            language: localStorage.getItem("ln") || language || AuthService.language,
            solution: solution || AuthService.loginInfo?.solution,

            "jak-tenant": tenant || AuthService.loginInfo?.tenant,
            "jak-project": project || AuthService.loginInfo?.project,
            "jak-language": localStorage.getItem("ln") || language || AuthService.language,
            "jak-solution": solution || AuthService.loginInfo?.solution,
            "jak-default-timeZone": solution || AuthService.loginInfo?.defaultTimeZone,
            "jak-client-timeZone": solution || this.resolveTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone),
        };


        if (AuthService.authorized) {
            headers.user = AuthService.loginInfo?.user;
            headers["jak-user"] = AuthService.loginInfo?.user;
        }
        // access token
        const accessToken =
            token ||
            AuthService.token ||
            AuthService.accessToken;

        if (accessToken && accessToken.length > 0) {
            headers["Authorization"] = `Bearer ${accessToken}`;
        }

        const clId = clusterId || AuthService.getProfileFields()?.clusterId;
        if (clId) {
            headers["jak-cms-cluster"] = clId;
        }

        return headers;
    }

    /**
     * Configure axios client
     */
    static configureAxios(setLoading: (status: boolean, error?: any) => void) {

        axios.interceptors.request.use(
            (config) => {
                setLoading(true);
                config.headers = {
                    ...this.getHttpHeaders({}),
                    ...config.headers, // THIS INSTRUCTION MUST BE AFTER '...this.getHttpHeaders()' cause 'this.getHttpHeaders()' are default headers and sometimes user needs to override data
                };

                return config;
            },
            (error) => {
                setLoading(false);
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            (response) => {
                setLoading(false);
                return response;
            },
            (error) => {

                setLoading(false);

                if (!error || !error?.response || !error?.response?.status) {
                    return;
                }

                switch (error.response.status) {
                    case 504:
                        browserRedirect(getNavPathFromType(NavigationTypes.SERVER_ERROR));
                        break;
                }


                return Promise.reject(error);
            }
        );
    }

    /**
     * Get config for project
     */
    static async downloadConfigJSON(): Promise<any> {

        try {
            const res = await axios.get(`/config.json`);
            this.setEnv(res?.data);
            return res?.data;
        } catch (e) {
            console.log('config.json download error: ', e);
        }


        /*.then((res: any) => {
            return this.setEnv(res?.data);
        })
        .catch(e => console.log('config.json download error: ', e));*/
    }

    static async getLocalDomain(): Promise<LocalDomain> {

        const loginInfo = AuthService.loginInfo;
        let language = DEFAULT_LANGUAGE;
        const host = (location?.hostname === 'localhost' || location?.hostname === '127.0.0.1') ? this.env?.hostName : location?.hostname;
        // @ts-ignore
        const domainApi = new DomainApi(undefined, this.servicePath('core-configuration'), axios)
        const res: DomainDto = (await domainApi.getDomains(host))?.data[0];

        if (!res) {
            return {
                loaded: false,
                language: DEFAULT_LANGUAGE
            };
        }

        if (res?.projects) {

            loginInfo.project = res?.projects[0]?.logicId;
            AuthService.setLoginInfo(loginInfo);
            localStorage.setItem('project', res?.projects[0]?.logicId as string);
            if(!!res?.projects[0]?.languages?.length){
                appStore.setLanguages(res?.projects[0]?.languages);
            }
            this.setCustomProject(res?.projects[0]);
            if (res?.projects[0]?.landingPageScope) {
                this.setLandingPageScope(res?.projects[0]?.landingPageScope)
            }

            language = res?.projects[0]?.languages?.filter((el: any) => el?.defaultLanguage)[0]?.cd_lng?.toLowerCase() ?? DEFAULT_LANGUAGE;

            let currentLanguageStillExist = res?.projects[0]?.languages?.filter( (language) => {
                return language.cd_lng?.toLowerCase() === localStorage.getItem("ln")
            })

            localStorage.setItem("ln", (currentLanguageStillExist?.length ?? 0) > 0 ? (localStorage.getItem("ln") ?? DEFAULT_LANGUAGE) : language);
        }

        if (res?.tenant) {
            loginInfo.tenant = res?.tenant?.logicId;
            loginInfo.defaultTimeZone = res?.projects?.[0]?.defaultTimezone ?? 'Europe/Rome';
            AuthService.setLoginInfo(loginInfo);
            localStorage.setItem('tenant', res?.tenant?.logicId as string)
        }

        //set initiative format contest
        const initiativeFormatContest = res?.modules?.find(module => module?.externalId === 'format-contest')?.initiatives;
        if (initiativeFormatContest) {
            this.setInitiativeFormat(initiativeFormatContest)
        }

        const digitalWallet = res?.modules?.find(el => el.externalId === 'format-digital-wallet');
        if (digitalWallet) {
            CatalogService.saveCatalogInfo(CatalogType.digitalWallet, {initiatives: digitalWallet?.initiatives || []});
        }

        const loyaltyCollection = res?.modules?.find(el => el.externalId === 'format-loyalty-collection');
        if (loyaltyCollection) {
            CatalogService.saveCatalogInfo(CatalogType.loyaltyCollection, {initiatives: loyaltyCollection?.initiatives || []});
        }

        return {
            loaded: true,
            language: localStorage.getItem("ln") ?? DEFAULT_LANGUAGE
        };


    }

    static async searchMessages(language = "it"): Promise<MessageFlatResponseArray> {
        const messageApi = new MessageApi(undefined, this.servicePath('core-configuration'), axios as any);
        return (await messageApi.searchMessages(language))?.data;
    }
}